<template>
  <div class="col pt-4 px-3 bg-greenish">
    <h4 style="color: #5588c3" class="mt-4">Integration With Your Own Apps</h4>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="form-group col-md-10 mt-2">
          <br />
          <p>Fill your credentials below</p>
        </div>

        <div class="form-group col-md-10 mb-3">
          <strong class="d-block">Project Id</strong>
          <input type="text" class="form-control" v-model="form.projectId" />
          <small class="d-block"
            >This url used for send and broadcasting message</small
          >
        </div>
        <div class="form-group col-md-10 mb-3">
          <strong class="d-block">Upload Your Private Key</strong>
          <input
            class="form-control form-control-md"
            type="file"
            accept=".json"
            id="formFile"
            @input="handleJsonChange"
            @change="(e) => $emit('change', e.target.files[0])"
          />
          <small class="d-block">Put Json file to this field</small>
        </div>

        <div class="form-group col-md-10">
          <strong class="d-block">Chat URL</strong>
          <small class="d-block">This url used for chat</small>
          <input
            type="text"
            class="form-control"
            id="chat-url"
            v-model="chatUrl"
            readonly
          />
          <img
            src="@/assets/images/ui_elements/icon_copy.png"
            @click.prevent="handleCopy('#chat-url')"
            class="img-prepend"
            alt=""
          />
          <small class="d-block"
            >Copy these webhook URL andd paste it as your application
            endpoint.</small
          >
        </div>

        <div class="form-group col-md-10">
          <strong class="d-block">Register URL</strong>
          <small class="d-block">This url used for register</small>
          <input
            type="text"
            class="form-control"
            id="register-url"
            v-model="registerUrl"
            readonly
          />
          <img
            src="@/assets/images/ui_elements/icon_copy.png"
            @click.prevent="handleCopy('#register-url')"
            class="img-prepend"
            alt=""
          />
        </div>

        <div class="form-group col-md-10">
          <strong class="d-block">Login URL</strong>
          <small class="d-block">This url used for login</small>
          <input
            type="text"
            class="form-control"
            id="login-url"
            v-model="loginUrl"
            readonly
          />
          <img
            src="@/assets/images/ui_elements/icon_copy.png"
            @click.prevent="handleCopy('#login-url')"
            class="img-prepend"
            alt=""
          />
        </div>

        <div class="form-group col-md-10">
          <strong class="d-block">Logout URL</strong>
          <small class="d-block">This url used for logout</small>
          <input
            type="text"
            class="form-control"
            id="logout-url"
            v-model="logoutUrl"
            readonly
          />
          <img
            src="@/assets/images/ui_elements/icon_copy.png"
            @click.prevent="handleCopy('#logout-url')"
            class="img-prepend"
            alt=""
          />
        </div>
        <div class="form-group col-md-10 mb-3">
          <div>
            <label class="form-label">Business Unit</label>
            <v-select
              label="label"
              name="bussines_unit"
              class="bg-white"
              :options="listBusinessUnit"
              :placeholder="`Select bussines unit`"
              v-model="businessUnit"
              @option:selected="changeValueBusinessUnit"
            />
          </div>
        </div>
        <div class="d-flex flex-row gap-2 mb-2">
          <input type="hidden" v-model="form.status" />
          <div>
            <button
              type="button"
              class="btn mr-3"
              :class="!form.status ? 'btn-secondary' : 'btn-danger'"
              :disabled="!form.status"
              @click="disconnectChannel"
            >
              Disconnect
            </button>
          </div>
          <div>
            <button type="submit" class="btn btn-primary">
              {{ form.status ? "Update" : "Connect" }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <lenna-modal ref="lennaModal"></lenna-modal>
  </div>
</template>

<script>
import Toast from "@/helpers/toast.helper";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
export default {
  data() {
    return {
      form: {
        channelName: "mobile",
        serverKey: "",
        projectId: "",
        status: false,
        business_unit: null,
      },
      loginUrl: "",
      logoutUrl: "",
      registerUrl: "",
      businessUnit: null,
    };
  },
  components: {
    vSelect,
  },
  created() {
    this.$store.dispatch("ticket/getBranchOffice");
  },
  computed: {
    ...mapGetters({
      listBusinessUnit: "ticket/getBranchOffice",
    }),
    chatUrl() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        "/api/" +
        this.$store.getters.appId.hashed +
        "/webhook/mobile"
      );
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    changeValueBusinessUnit(val) {
      this.form.business_unit_id = val.id;
      this.businessUnit = val.label;
    },
    handleCopy(selector) {
      let copyCode = document.querySelector(selector);
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
    async disconnectChannel() {
      try {
        let data = {
          channelName: this.form.channelName,
          status: false,
        };
        await this.$axios.put(
          `api/${this.$store.getters.appId.hashed}/integrate/update`,
          data
        );
        // response = response.data;

        this.emitter.emit("hideIntegrationModal");
        Toast.success("Channel disconected");
        this.form.status = false;
      } catch (error) {
        this.form.status = !this.form.status;
        Toast.error(error);
      }
    },

    handleJsonChange() {
      const elementJson = document.getElementById("formFile");
      elementJson.addEventListener("change", (event) => {
        const file = event.target.files[0];

        if (file && file.type === "application/json") {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const fileContent = reader.result;
              const jsonData = JSON.parse(fileContent);

              const jsonString = JSON.stringify(jsonData);

              this.form.serverKey = jsonString;
            } catch (error) {
              console.error("Gagal memproses file JSON:", error.message);
            }
          };

          reader.onerror = () => {
            console.error("Failed to read file.");
          };

          reader.readAsText(file); // Read the file as text
        } else {
          alert("Silakan unggah file JSON yang valid.");
        }
      });
    },
    async submitForm() {
      try {
        let formData = { ...this.form, status: true };
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/integrate`,
          formData
        );
        this.form.status = true;

        this.emitter.emit("hideIntegrationModal");
        Toast.success(response.data.message);
      } catch (error) {
        Toast.error(this);
      }
    },
    async fetchData() {
      await this.$store.dispatch(
        "integration/getIntegrationData",
        this.form.channelName
      );
      let data = await this.$store.getters["integration/integrationData"];
      if (data != null) {
        // this.form.serverKey = data.integration_data.serverKey;
        this.form.projectId = data.integration_data.projectId;
        this.form.status = data.status;
      }
    },
  },
  async mounted() {
    this.fetchData();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.bg-class {
  background-color: #fafafa;
  min-height: 750px;
  height: auto;
}
.img-prepend {
  position: relative;
  cursor: pointer;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
#formFile {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
